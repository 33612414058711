* {
    box-sizing: border-box;
}

.App-sale {
  color: #666666;
  font-weight: bold;
  text-align: center;
}


.App-root {
  font-family: 'Open Sans','Raleway', sans-serif;
  color: #333333;
  margin: 0;
  max-width: 100%;
    overflow-x: hidden;
}

h1 {
  font-family: 'Capriola', sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  color: #666666;
}

h2 {
  font-family: 'Capriola', sans-serif;
  font-weight: normal;
  font-size: 1.2em;
  color: #666666;
}

a {
  text-decoration: none;
}


.App-header a {
   color: #ffffff;
}

.App-main a {
  color: #42C0FB;
}

a:visited {
  text-decoration: none;
}

.App-header {
  background-color:#42C0FB;
  color: #ffffff; 
  position: relative;
  padding-top: 1em;
}


.App-main {
  padding-top: 1em;
  padding-left: 15%;
  padding-right: 15%;
}

.App-main > div > img {
  margin-top: 1em;
  max-width: 100%;
}

.App-logo {
  margin-left: 15%;
  font-family: 'Capriola', 'Sriracha', 'Imprima', sans-serif;
  font-size: 2em;
}

.padded {
  padding: 15px;
}



.App-navbar {
  float: right;
  font-family: 'Open Sans';
  position: absolute;
  right: 15%;
  bottom: 0.3em;
  display: none;
}

.App-navbar span {
  margin-left: 1em;
  margin-right: 1em;
}

.App-flavorimage {
  height: 70px;
  position: absolute;
  bottom: -15px;
  right: -5px;
  display: none;
}


.clickable {
  cursor: pointer;
}

.App-navbar .heading {
  font-weight: bold;
}

.App-col-side > img {
  width: 100%;
  max-width: 200px;
}

[class*="App-col-"] {
    float: left;
}

/* For mobile phones: */
[class*="App-col-"] {
    width: 100%;
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .App-col-side {width: 15%;}
    .App-col-main {width: 70%;}
    .App-navbar {display: block;}
    .App-leftbar {height: 100%; min-height: 100px;}
    .App-rightbar {height: 100%; min-height: 100px;}
    .App-main {padding-left: 0; padding-right: 0;}
    .App-flavorimage {display: block;}
}