.Store-filters span {
  margin-left: 1em;
  color: #42C0FB;
  cursor: pointer;
}

.Store-filters .selected {
  text-decoration: underline;
}

.Store-filters .heading {
  font-weight: bold;
  color: #333333;
  cursor: default;
}

.Store-root {
  margin-top: 2em;
}

.Store-item {
  float: left;
  width: 30%;
  min-width: 260px;
  margin: 1.5%;
}

.Store-itemstory {
  width: 100%;
  font-size: 0.8em;
  height: 14em;

}

.Store-item h1 {
  font-family: 'Capriola', sans-serif;
  font-size: 1.2em;
  text-align: center;
}

.Store-item a h1{
  color: #036fa1;
}