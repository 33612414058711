body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
    box-sizing: border-box;
}

.App-sale {
  color: #666666;
  font-weight: bold;
  text-align: center;
}


.App-root {
  font-family: 'Open Sans','Raleway', sans-serif;
  color: #333333;
  margin: 0;
  max-width: 100%;
    overflow-x: hidden;
}

h1 {
  font-family: 'Capriola', sans-serif;
  font-weight: normal;
  font-size: 1.5em;
  color: #666666;
}

h2 {
  font-family: 'Capriola', sans-serif;
  font-weight: normal;
  font-size: 1.2em;
  color: #666666;
}

a {
  text-decoration: none;
}


.App-header a {
   color: #ffffff;
}

.App-main a {
  color: #42C0FB;
}

a:visited {
  text-decoration: none;
}

.App-header {
  background-color:#42C0FB;
  color: #ffffff; 
  position: relative;
  padding-top: 1em;
}


.App-main {
  padding-top: 1em;
  padding-left: 15%;
  padding-right: 15%;
}

.App-main > div > img {
  margin-top: 1em;
  max-width: 100%;
}

.App-logo {
  margin-left: 15%;
  font-family: 'Capriola', 'Sriracha', 'Imprima', sans-serif;
  font-size: 2em;
}

.padded {
  padding: 15px;
}



.App-navbar {
  float: right;
  font-family: 'Open Sans';
  position: absolute;
  right: 15%;
  bottom: 0.3em;
  display: none;
}

.App-navbar span {
  margin-left: 1em;
  margin-right: 1em;
}

.App-flavorimage {
  height: 70px;
  position: absolute;
  bottom: -15px;
  right: -5px;
  display: none;
}


.clickable {
  cursor: pointer;
}

.App-navbar .heading {
  font-weight: bold;
}

.App-col-side > img {
  width: 100%;
  max-width: 200px;
}

[class*="App-col-"] {
    float: left;
}

/* For mobile phones: */
[class*="App-col-"] {
    width: 100%;
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .App-col-side {width: 15%;}
    .App-col-main {width: 70%;}
    .App-navbar {display: block;}
    .App-leftbar {height: 100%; min-height: 100px;}
    .App-rightbar {height: 100%; min-height: 100px;}
    .App-main {padding-left: 0; padding-right: 0;}
    .App-flavorimage {display: block;}
}
a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
} 

.BurgerMenu-image {
  height: 1.8em;
  position: absolute;
  right: 15%;
  top: 1em;
}
.BurgerMenu-main {
  width: 100%;
}

.BurgerMenu-slide {
  background-color:#666666;
  color: #ffffff;
  padding-left: 15%;
  padding-right: 15%;
}

.BurgerMenu-slide span {
	margin-top: 1em;
	margin-bottom: 1em;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .BurgerMenu-main {display: none;}
}
.Store-filters span {
  margin-left: 1em;
  color: #42C0FB;
  cursor: pointer;
}

.Store-filters .selected {
  text-decoration: underline;
}

.Store-filters .heading {
  font-weight: bold;
  color: #333333;
  cursor: default;
}

.Store-root {
  margin-top: 2em;
}

.Store-item {
  float: left;
  width: 30%;
  min-width: 260px;
  margin: 1.5%;
}

.Store-itemstory {
  width: 100%;
  font-size: 0.8em;
  height: 14em;

}

.Store-item h1 {
  font-family: 'Capriola', sans-serif;
  font-size: 1.2em;
  text-align: center;
}

.Store-item a h1{
  color: #036fa1;
}

.ImageCarousel-circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border-color: #CCCCCC;
      border-style: solid;
      border-width: 1px;
      margin: 2px;
      float: left;
      cursor: pointer;
}

.ImageCarousel-circle-selected {
	width: 15px;
    height: 15px;
    border-radius: 50%;
	background: #CCCCCC;
	border-color: #CCCCCC;
	border-style: solid;
	border-width: 1px;
	margin: 2px;
	float: left;
	cursor: pointer;
}

.ImageCarousel-bunny {
    width: 20px;
    height: 20px;
	stroke: #CCCCCC;
	fill:none;
    margin: 2px;
    float: left;
    cursor: pointer;
    stroke-width: 2px;
}

.ImageCarousel-bunny circle{
	stroke: none;
}

.ImageCarousel-bunny-selected {
	width: 20px;
    height: 20px;
	fill: white;
	stroke: #ff78bb;
	margin: 2px;
	float: left;
	cursor: pointer;
	stroke-width: 2px;
}

.ImageCarousel-bunny-selected circle{
	fill: #ff78bb;
	stroke: white;
}




.ImageCarousel-image-wrapper {
	width: 100%;
	padding-top: 100%;
	position: relative;
}

.ImageCarousel-image-wrapper > img{
	width: 100%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
    border-radius: 2px;
}

.ImageCarousel-caption {
	text-align: center;
	width: 100%;
	color: #666666;
	font-size: 0.7em;
	font-family: 'Capriola', sans-serif;
	margin: 0.1em;
}

.ImageCarousel-main {

}

.ImageCarousel-circle-wrapper {
	width: 100%;
	text-align: center;
	position: relative;
	color: #CCCCCC;
	font-weight: bold;
	font-size: 1.4em;
	margin: 2px;
}

.ImageCarousel-circle-panel {
	display: inline-block;
}

.ImageCarousel-leftarrow {
	position: absolute;
	left: -20px;
	top: -20px;
	padding: 20px;
	cursor: pointer;
}

.ImageCarousel-rightarrow {
	position: absolute;
	right: -20px;
	top: -20px;
	padding: 20px;
	cursor: pointer;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}



@media only screen and (min-width: 768px) {
    /* For desktop: */
    .ImageCarousel-circle-wrapper { font-size: 1em; }
    .ImageCarousel-circle {
      width: 10px;
      height: 10px;
	}

	.ImageCarousel-circle-selected {
		width: 10px;
	    height: 10px;
	}

	.ImageCarousel-bunny {
		width: 15px;
		height: 15px;
	}

	.ImageCarousel-bunny-selected {
		width: 15px;
		height: 15px;
	}
}
