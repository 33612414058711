
.ImageCarousel-circle {
      width: 15px;
      height: 15px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border-color: #CCCCCC;
      border-style: solid;
      border-width: 1px;
      margin: 2px;
      float: left;
      cursor: pointer;
}

.ImageCarousel-circle-selected {
	width: 15px;
    height: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
	background: #CCCCCC;
	border-color: #CCCCCC;
	border-style: solid;
	border-width: 1px;
	margin: 2px;
	float: left;
	cursor: pointer;
}

.ImageCarousel-bunny {
    width: 20px;
    height: 20px;
	stroke: #CCCCCC;
	fill:none;
    margin: 2px;
    float: left;
    cursor: pointer;
    stroke-width: 2px;
}

.ImageCarousel-bunny circle{
	stroke: none;
}

.ImageCarousel-bunny-selected {
	width: 20px;
    height: 20px;
	fill: white;
	stroke: #ff78bb;
	margin: 2px;
	float: left;
	cursor: pointer;
	stroke-width: 2px;
}

.ImageCarousel-bunny-selected circle{
	fill: #ff78bb;
	stroke: white;
}




.ImageCarousel-image-wrapper {
	width: 100%;
	padding-top: 100%;
	position: relative;
}

.ImageCarousel-image-wrapper > img{
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.ImageCarousel-caption {
	text-align: center;
	width: 100%;
	color: #666666;
	font-size: 0.7em;
	font-family: 'Capriola', sans-serif;
	margin: 0.1em;
}

.ImageCarousel-main {

}

.ImageCarousel-circle-wrapper {
	width: 100%;
	text-align: center;
	position: relative;
	color: #CCCCCC;
	font-weight: bold;
	font-size: 1.4em;
	margin: 2px;
}

.ImageCarousel-circle-panel {
	display: inline-block;
}

.ImageCarousel-leftarrow {
	position: absolute;
	left: -20px;
	top: -20px;
	padding: 20px;
	cursor: pointer;
}

.ImageCarousel-rightarrow {
	position: absolute;
	right: -20px;
	top: -20px;
	padding: 20px;
	cursor: pointer;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}



@media only screen and (min-width: 768px) {
    /* For desktop: */
    .ImageCarousel-circle-wrapper { font-size: 1em; }
    .ImageCarousel-circle {
      width: 10px;
      height: 10px;
	}

	.ImageCarousel-circle-selected {
		width: 10px;
	    height: 10px;
	}

	.ImageCarousel-bunny {
		width: 15px;
		height: 15px;
	}

	.ImageCarousel-bunny-selected {
		width: 15px;
		height: 15px;
	}
}