a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
} 

.BurgerMenu-image {
  height: 1.8em;
  position: absolute;
  right: 15%;
  top: 1em;
}
.BurgerMenu-main {
  width: 100%;
}

.BurgerMenu-slide {
  background-color:#666666;
  color: #ffffff;
  padding-left: 15%;
  padding-right: 15%;
}

.BurgerMenu-slide span {
	margin-top: 1em;
	margin-bottom: 1em;
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .BurgerMenu-main {display: none;}
}